<template>
  <div class="warpperContent">
    <v-card>
      <v-card-text>
        <div class="d-flex align-center">
          <img src="../../assets/images/other/laptop-2298286.svg" alt="laptop" class="img_bg" />
          <p class="mb-0 ms-4 font-weight-bold tag_pSize">Shop Za</p>
        </div>
        <h2 class="mt-3">รีวิวสินค้า</h2>
        <hr class="mt-3 mb-3" />
        <!-- header content -->
        <v-row>
          <v-col md="2" sm="4" cols="12" class="pb-0">
            <p class="mb-0">บิลเลขที่</p>
          </v-col>
          <v-col md="10" sm="8" cols="12" class="pb-0">
            <p class="mb-0 font-weight-bold">#{{ this.$store.state.numberBill }}</p>
          </v-col>
          <v-col md="2" sm="4" cols="12" class="pt-1 pb-0">
            <p class="mb-0">ชื่อลูกค้า</p>
          </v-col>
          <v-col md="10" sm="8" cols="12" class="pt-1 pb-0">
            <p class="mb-0 font-weight-bold">{{ this.$store.state.nameCustomer }}</p>
          </v-col>
          <v-col md="2" sm="4" cols="12" class="pt-1 pb-0">
            <p class="mb-0">ออกบิลเมื่อ</p>
          </v-col>
          <v-col md="10" sm="8" cols="12" class="pt-1 pb-0">
            <p class="mb-0 font-weight-bold">{{ this.$store.state.timePay }}</p>
          </v-col>
        </v-row>
        <!-- end header content -->
        <hr class="mt-8" />
        <li v-for="item in this.$store.state.mockData" :key="item.id">
          <v-row class="mt-5 pb-3">
            <v-col md="6" sm="6" cols="12">
              <h2>{{ item.nameProduct }}</h2>
              <div class="d-flex align-center">
                <v-rating v-model="rating" background-color="star" color="star" x-large></v-rating>
                <p class="mb-0">({{ rating }})</p>
              </div>
              <img :src="item.img" alt="image product" class="image_product" />
            </v-col>
            <v-col md="6" sm="6" cols="12" class="contentReview">
              <h2>ให้คะแนนสินค้า</h2>
              <v-rating v-model="ratingBig" background-color="star" color="star" size="64"></v-rating>
              <v-textarea
                v-model="item.review"
                label="เขียนรีวิวสินค้า"
                hide-details
                auto-grow
                outlined
                rows="1"
                row-height="15"
              ></v-textarea>
            </v-col>
          </v-row>
          <hr class="mt-2 mb-2" />
        </li>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    rating: 0,
    ratingBig: 0,
  }),
}
</script>

<style scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
img {
  height: 5.15rem;
  width: 4.235rem;
  border-radius: 50%;
}
li {
  list-style-type: none;
}
.img_bg {
  background-color: #78a2cc;
  width: 5.25rem;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.tag_pSize {
  font-size: 20px;
}
.image_product {
  width: 18.75rem;
  height: 18.75rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.contentReview {
  right: 0;
}
.warpperContent {
  padding: 2rem 2rem;
  width: 70%;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .warpperContent {
    padding: 2rem 2rem;
  }
}
</style>
